@if (upperMenu(); as upperMenu) {
  <nav
    class="topbar-uppernav container container-width-offset"
    #topUpperbar
    (fstrInViewport)="inview($event)"
    [once]="false"
    [class.position-fixed]="!collapseOnScroll()"
  >
    <ng-content select="[slot=alert]"></ng-content>

    <ul class="topbar-uppernav__list list-unstyled container-width-offset d-none d-lg-flex ms-auto">
      <li class="country-switcher">
        @for (country of languageOptions(); track country) {
          <fstr-link
            cssClasses=""
            [button]="{ text: '', url: 'https://' + (isStaging() ? country.stagingUrl : country.url) }"
            [routerLinkActive]="'is--active'"
            [routerLinkActiveOptions]="{ exact: true }"
            [showText]="false"
            (click)="closeOverlayMenu()"
          >
            <img class="country-switcher__flag" [src]="'/assets/gfx/flags/flag-' + country.code + '.svg'" />
          </fstr-link>
        }
      </li>
      @for (page of upperMenu | ofMenuType: 'MenuItem'; track page) {
        <li>
          <fstr-link
            cssClasses="button button--primary topbar-button-label"
            [button]="{ text: page.title, url: page.url || '', routerLink: page.routerLink }"
            [routerLinkActive]="'is--active'"
            [routerLinkActiveOptions]="{ exact: page.url === '/' }"
            [showText]="false"
            (click)="closeOverlayMenu()"
            class="d-contents"
          >
            <span>{{ page.title }}</span>
          </fstr-link>
        </li>
      }
    </ul>
  </nav>
}

<div class="topbar" #topbar [class.set--fixed]="!fixedNav$()" [class.menu--active]="menuActive()">
  <div class="container container-width-offset topbar__container">
    <nav class="topbar__main bg-light d-flex align-items-center">
      <h1 class="topbar__logo ms-lg-3 me-2 mb-0">
        <span class="d-none">{{ 'meta-titel' | fromDictionary }}</span>
        <a [routerLink]="'/'" (click)="closeOverlayMenu()">
          <img
            width="200"
            height="74"
            [src]="('eurocross-logo' | fromDictionary: false) || '/assets/gfx/logo-eurocross-horizontal.svg'"
            [alt]="'meta-titel' | fromDictionary"
          />
        </a>
      </h1>
      <div class="topbar-mainnav d-lg-flex align-items-center" [class.set--active]="menuActive()">
        <ul class="topbar-mainnav__list menu--default d-lg-flex">
          @for (page of menuDefault$(); track page) {
            <li class="topbar-mainnav__list__item" [class.has--subnav]="page.menuItems.length" #toggleButton>
              @if (page.menuItems.length > 0) {
                <button
                  class="button button-link button--level-1 toggle--subnav-button button--dark"
                  [class.set--active]="activeButton$() === page.guid"
                  (click)="toggleClass(page)"
                >
                  <span class="toggle--subnav-button__label" [routerLinkActive]="'text-primary'" [routerLink]="page.routerLink">
                    {{ page.title }}
                  </span>

                  <span class="ms-auto me-0"><fstr-icon anchor="icon-chevron-down" [viewbox]="'0 0 24 24'"></fstr-icon></span>
                </button>
              } @else {
                <fstr-link
                  cssClasses="button button-link button--level-1 button--dark"
                  [button]="{ text: page.title, url: page.url || '', routerLink: page.routerLink }"
                  [routerLinkActive]="'is--active'"
                  [routerLinkActiveOptions]="{ exact: page.url === '/' }"
                  [showText]="false"
                  (click)="closeOverlayMenu()"
                >
                  <span>
                    {{ page.title }}
                  </span>
                </fstr-link>
              }
              @if (page.menuItems.length) {
                <nav class="topbar-subnav">
                  <div class="container container-width-offset">
                    <div class="topbar-subnav__wrapper d-lg-flex">
                      <div class="topbar-subnav__list col-lg-15">
                        @if (page.label) {
                          <h3 class="text-primary h6 fw-normal mb-3 mb-lg-4">{{ page.label }}</h3>
                        }

                        <ul class="list-unstyled d-flex flex-column flex-md-row flex-wrap gap-lg-4">
                          @for (subitem of page.menuItems | ofMenuType: 'MenuItem'; track subitem) {
                            <li class="topbar-subnav__list__item">
                              <fstr-link
                                cssClasses="button button-link  button--dark"
                                [button]="{ text: subitem.title, url: subitem.url || '', routerLink: subitem.routerLink }"
                                [routerLinkActive]="'is--active'"
                                [routerLinkActiveOptions]="{ exact: subitem.url === '/' }"
                                [showText]="false"
                                (click)="closeOverlayMenu()"
                              >
                                <span class="gap-2 align-items-center d-flex">
                                  {{ subitem.title }}
                                  @if (!subitem.routerLink) {
                                    <fstr-icon anchor="line-icon-external-link"></fstr-icon>
                                  }
                                </span>

                                <em class="text-muted d-none d-lg-block fs-body-xs">{{ subitem.label }}</em>
                              </fstr-link>
                            </li>
                          }
                        </ul>
                      </div>

                      @if (page.menuItems | ofMenuType: 'CTA'; as ctaItems) {
                        @if (ctaItems.length) {
                          <aside class="topbar-subnav__aside col-lg-9 mt-5 mt-md-0 d-flex flex-column gap-5">
                            @for (subitem of ctaItems; track subitem) {
                              <article>
                                <h3 class="text-primary h6 fw-normal mb-3 mb-lg-4">{{ subitem.label }}</h3>
                                <h2 class="h5">{{ subitem.title }}</h2>
                                <p class="text-muted fs-body-xs pe-xl-7">
                                  {{ subitem.text }}
                                </p>
                                <footer class="mt-auto mt-5 mt-md-0 d-flex flex-wrap gap-1">
                                  <fstr-link
                                    [button]="{
                                      url: subitem.primaryButton.url,
                                      text: subitem.primaryButton.label,
                                      routerLink: subitem.primaryButton.routerLink,
                                    }"
                                    [queryParams]="subitem.primaryButton.queryParams"
                                    [cssClasses]="'button button--primary'"
                                  >
                                    @if (!subitem.primaryButton.routerLink) {
                                      <fstr-icon [viewbox]="'0 0 24 24'" anchor="line-icon-external-link"></fstr-icon>
                                    }
                                  </fstr-link>

                                  @if (subitem.secondaryButton?.url) {
                                    <fstr-link
                                      [button]="{
                                        url: subitem.secondaryButton!.url!,
                                        text: subitem.secondaryButton!.label!,
                                        routerLink: subitem.secondaryButton!.routerLink,
                                      }"
                                      [cssClasses]="'button button-link-chip button--secondary'"
                                    >
                                      @if (!subitem.secondaryButton!.routerLink) {
                                        <fstr-icon anchor="line-icon-external-link"></fstr-icon>
                                      } @else {
                                        <fstr-icon anchor="icon-arrow-right"></fstr-icon>
                                      }
                                    </fstr-link>
                                  }
                                </footer>
                              </article>
                            }
                          </aside>
                        }
                      }
                      <span class="subnav--overlay" (click)="closeOverlayMenu()"></span>
                    </div>
                  </div>
                </nav>
              }
            </li>
          }
        </ul>
        <ul class="topbar-mainnav__list menu--highligted ms-auto d-flex gap-3 gap-lg-1">
          @for (page of menuHiglight$(); track page; let i = $index) {
            <li class="topbar-mainnav__list__item">
              <fstr-link
                [cssClasses]="i === 0 ? 'button button--primary button--level-1' : 'button button--primary-100 button--level-1'"
                [button]="{ text: page.title, url: page.url || '', routerLink: page.routerLink }"
                [routerLinkActive]="'is--active'"
                [routerLinkActiveOptions]="{ exact: page.url === '/' }"
                [showText]="false"
                (click)="closeOverlayMenu()"
              >
                <span>{{ page.title }}</span>
              </fstr-link>
            </li>
          }
        </ul>

        @if (upperMenu(); as upperMenu) {
          <ul class="d-lg-none mt-3">
            @for (page of upperMenu | ofMenuType: 'MenuItem'; track page) {
              <li>
                <fstr-link
                  cssClasses="button button-link button--primary w-100"
                  [button]="{ text: page.title, url: page.url || '', routerLink: page.routerLink }"
                  [routerLinkActive]="'is--active'"
                  [routerLinkActiveOptions]="{ exact: page.url === '/' }"
                  [showText]="false"
                  (click)="closeOverlayMenu()"
                >
                  <span>{{ page.title }}</span>
                </fstr-link>
              </li>
            }
          </ul>
        }
        @if (!isMsc) {
          <li class="country-switcher mt-4">
            <ul>
              @for (country of languageOptions(); track country) {
                <li>
                  <fstr-link
                    cssClasses=""
                    [button]="{ text: '', url: 'https://' + (isStaging() ? country.stagingUrl : country.url) }"
                    [routerLinkActive]="'is--active'"
                    [routerLinkActiveOptions]="{ exact: true }"
                    [showText]="false"
                    (click)="closeOverlayMenu()"
                    [target]="'_self'"
                  >
                    <img class="country-switcher__flag" [src]="'/assets/gfx/flags/flag-' + country.code + '.svg'" />
                  </fstr-link>
                </li>
              }
            </ul>
          </li>
        }
      </div>
      <button
        class="button button--primary-100 text-decoration-none ms-auto me-3 d-lg-none"
        aria-label="Toon uitgebreid menu"
        [class.is--active]="menuActive()"
        (click)="toggleOverlay.emit()"
      >
        @if (menuActive()) {
          <span>Sluiten</span>
        } @else {
          <span>Menu</span>
        }
        <span class="hamburger" [class.is--active]="menuActive()">
          <span class="hamburger__lines"></span>
        </span>
      </button>
    </nav>
  </div>
</div>
